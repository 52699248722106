import React from 'react';
import { Link, graphql } from 'gatsby';
import { useTranslation, Trans } from 'react-i18next';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import SEO from '../components/seo';
import { PageTitle } from '../components/styles/global';
import statutFile from '../downloads/ppp-statut.pdf';
import DownloadIcon from '../images/download.inline.svg';

export const QUERY = graphql`
  {
    graphAPI {
      allPiercers {
        id
      }
    }
  }
`;

const About = ({
  data: {
    graphAPI: { allPiercers },
  },
}) => {
  const { t } = useTranslation('translation');
  const facebookLink = (
    <OutboundLink
      className="link"
      target="_blank"
      href="https://facebook.com/professionalpiercerspoland"
    >
      professionalpiercerspoland
    </OutboundLink>
  );
  const instagramLink = (
    <OutboundLink
      className="link"
      target="_blank"
      href="https://instagram.com/professionalpiercerspoland"
    >
      @professionalpiercerspoland
    </OutboundLink>
  );
  const piercersLength = allPiercers ? allPiercers.length : 30;

  return (
    <>
      <SEO title={t('aboutUs')} />
      <PageTitle>{t('aboutUs')}</PageTitle>
      <p>{t('associationHistory')}</p>
      <p>{t('associationMainAspects')}:</p>
      <ol>
        <li>{t('aspectOne')}</li>
        <li>{t('aspectTwo')}</li>
        <li>{t('aspectThree')}</li>
      </ol>
      <p>
        <Trans
          i18nKey="aspectSummary"
          values={{
            numberOfMembers: piercersLength,
          }}
          components={[facebookLink, instagramLink]}
        />
      </p>

      <h2>{t('mission')}</h2>
      <p>{t('missionDetails')}</p>
      <h2>{t('vision')}</h2>
      <p>{t('visionDetails')}</p>
      <h2>{t('targets')}</h2>
      <p>{t('mainTargets')}:</p>
      <ol>
        <li>{t('target1')}</li>
        <li>{t('target2')}</li>
        <li>{t('target3')}</li>
      </ol>
      <h2>{t('statute')}</h2>
      <p>
        <a href={statutFile} className="file" target="_blank" rel="noopener noreferrer">
          <DownloadIcon width="18" />
          {t('statuteDownloadLink')}
        </a>
      </p>

      <div>
        <p>{t('perks')}:</p>
        <ul>
          <li>{t('perk1')}</li>
          <li>{t('perk2')}</li>
          <li>{t('perk3')}</li>
          <li>{t('perk4')}</li>
          <li>{t('perk5')}</li>
          <li>{t('perk6')}</li>
        </ul>
      </div>
    </>
  );
};
export default About;

